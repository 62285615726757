import Head from 'next/head'
import WhySupermeme from '@/components/website/WhySupermeme'
import HowItWorks from '@/components/website/HowItWorks'
import WhyYouNeedIt from '@/components/website/WhyYouNeedIt'
import WhoNeedsIt from '@/components/website/WhoNeedsIt'
import MemeAPICTA from '@/components/website/MemeAPICTA'
import FAQ from '@/components/website/FAQ'
import CTAComponent from '@/components/website/core/CTA'
import HeroLanding from '@/components/website/core/HeroLanding'

const faqs = [
  {
    question: 'How to make an AI meme?',
    answer:
      "Just enter the text you want within Supermeme.ai and hit enter. That's it! You will get 8 AI Memes to choose from.",
  },
  {
    question: 'Is this a meme generator?',
    answer:
      "Yes! Supermeme.ai is an AI meme generator that lets you generate memes by simply typing in a text. We're building a meme generator that's purpose built for meme creation. You can add your own text, resize the font and move things around just as you would on other meme generators.",
  },
  {
    question: 'How is this meme generator different from others?',
    answer:
      "Supermeme.ai is built only for meme creation. It differs from other meme generators in several ways: 1) Curated meme template database: Our collection is manually curated and tagged with a meme creator's intention in mind. 2) Purpose built meme editor: We carefully pick only the capabilities you need to create and edit memes. 3) Multilingual memes: You can generate memes in over 110+ languages automatically by simply choosing a meme output language.",
  },
  {
    question: 'Can I upload my own meme templates?',
    answer:
      'Absolutely! You can upload your own templates and use our AI to generate memes on that image.',
  },
  {
    question: 'How does AI meme generation work?',
    answer:
      "Supermeme.ai uses a combination of OpenAI's GPT-3 and in-house carefully curated images to generate meme captions and match them with relevant meme templates. You can generate AI memes in two ways: 1) Generate memes from any topic, or 2) Generate memes from text.",
  },
  {
    question: 'Are AI memes safe to use?',
    answer:
      "We've taken steps to ensure that AI memes generated in Supermeme.ai are appropriate to be shared publicly. We filter out potentially offensive and NSFW meme templates, and use OpenAI for text moderation.",
  },
  {
    question: 'Are AI generated memes funny?',
    answer:
      'The randomness of AI often works in our favor since the funniest memes are also random. You can see examples of AI generated memes on our website.',
  },
  {
    question: 'Are memes copyrighted?',
    answer:
      'Memes are usually covered under the "fair usage" policy. Many companies already use memes extensively in their marketing. However, you should get an opinion from a legal expert for your specific business!',
  },
  {
    question: 'Does Supermeme.ai offer APIs?',
    answer:
      'Yes, we do! You can generate memes via APIs by sending in a text input. Read more on our website.',
  },
]

export default function HomePage() {
  return (
    <div>
      <Head>
        <title>
          Turn text into AI memes | AI Meme Generator | Supermeme.ai
        </title>
        <meta
          name='description'
          content='Supermeme.ai is an AI meme generator that can turn any text into a meme. Generate AI memes from any topic. Free trial. No Credit Card required.'
        />
      </Head>

      <HeroLanding />

      {/* Why Supermeme section */}
      <WhySupermeme />

      {/* How it works section */}
      <HowItWorks />

      {/* ... any other sections ... */}
      <WhyYouNeedIt />

      {/* Who needs it section */}
      <WhoNeedsIt />

      {/* Meme API CTA section */}
      <MemeAPICTA />

      {/* FAQ section */}
      <FAQ faqs={faqs} />

      {/* New section */}
      <div className='relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto max-w-2xl text-center'>
            <p className='text-base font-semibold leading-7 text-indigo-600'>
              Meme faster
            </p>
            <h2 className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
              A better meme workflow
            </h2>
            <p className='mt-6 text-xl leading-8 text-gray-700'>
              Create, edit, and share memes with ease. Our platform streamlines
              the meme-making process, allowing you to focus on creativity and
              humor.
            </p>
          </div>

          <div className='mt-16 flex justify-center'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0'
                  >
                    Feature
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-bold text-gray-900'
                  >
                    Supermeme
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-bold text-gray-900'
                  >
                    Others
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    AI-powered meme generation
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    Customizable templates
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    Multilingual support
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    API Access
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
                <tr>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                    Export memes for social media
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✓</td>
                  <td className='px-3 py-4 text-sm text-gray-500'>✗</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='mt-16 text-center'>
            <div className='mx-auto max-w-xl text-base leading-7 text-gray-700'>
              <p>
                Supermeme provides a comprehensive suite of tools designed to
                enhance your meme creation experience. From template selection
                to text overlay, our platform offers everything you need to
                craft the perfect meme.
              </p>
              <p className='mt-8'>
                Whether you&apos;re a casual meme enthusiast or a professional
                content creator, Supermeme offers the tools and flexibility you
                need to bring your ideas to life.
              </p>
              <h2 className='mt-16 text-2xl font-bold tracking-tight text-gray-900'>
                No design skills? No problem.
              </h2>
              <p className='mt-6'>
                Our intuitive interface and vast library of templates make it
                easy for anyone to create hilarious and engaging memes. With
                Supermeme, you don&apos;t need to be a graphic designer to make
                memes that stand out and go viral.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <CTAComponent />
      </div>
    </div>
  )
}
